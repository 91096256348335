.Selector{
    overflow: hidden;
    padding-top: 40vh;
    transition: all .4s ease-in-out;
}

.flexContainer{
    display: flex;
    flex-direction: column;
    align-items: stretch;
}



@media all and (orientation:landscape) {
    .Selector{
        height: 100vh;
        padding-top: 0;
    }
}