.Image{
    background: url('../img/IH01_nomadd.jpg') no-repeat center center;
    width: 100%;
    height: 100%;
    background-size: cover;
}

@media all and (orientation:landscape) {
    .Image{

    }
}