.ServiceDefault{
    background-color: #e5eaf0;
    border-bottom: 1px solid darkgray;
}

.flexItem{
    flex-grow: 1;
}

.nestedFlexContainer{
    display: flex;
}