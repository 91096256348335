.ServiceIcon{
    float: left;
    background-color: #c7c7c7;
    margin: calc(2vmin - 1px);
    flex-grow: 1;
    width: 32vmin;    
}

#msg{
    background: url('../svg/message.svg') no-repeat center center;
    background-size: 14vmax;
}

#msgDark{
    background: url('../svg/messageDark.svg') no-repeat center center;
    background-size: 14vmax;
}

#tel{
    background: url('../svg/call.svg') no-repeat center center;
    background-size: 14vmax;
}

#doc{
    background: url('../svg/Document.svg') no-repeat center center;
    background-size: 14vmax;
}

#faq{
    background: url('../svg/faq.svg') no-repeat center center;
    background-size: 14vmax;
}

#drop{
    background: url('../svg/dropDark.svg') no-repeat center center;
    background-size: 14vmax;
}

#cart{
    background: url('../svg/cartDark.svg') no-repeat center center;
    background-size: 14vmax;
}

.ServiceIcon>img{
    height: 14vh;
    opacity: 1.0;
    
}

@media all and (orientation:landscape) {
    .ServiceIcon>img{
        height: calc( (100vh - 6 * 12px) / 3 );
        height: calc( (100vh - 6 * (2vh - -2px)) / 3 );
    }

    .ServiceIcon{
        background-size: calc( (80vh - 6 * (2vh - -2px)) / 3 );
    }
}