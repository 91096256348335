.ServiceList{
    background-color: white;
    overflow: scroll;
    transition: all .4s ease-in-out;
    /* border-top: 1px solid #ffc60e; */
    /* max-height: 60vh; */
    /* padding: 2px; */
}

.ServiceListDescription{
    color: gray;
    font-size: 0.9em;
    margin: 17px;
}

.ServiceListName{
    margin: 18px 18px 8px 18px;
    display: block;
    font-size: 1.2em;
    border-bottom: 1px solid hsl(0, 0%, 50%);
}

.msgButton{
    transition: all .2s ease-in-out;
    
    color: hsl(197, 100%, 42%);
    margin: 2px 12px;
    /* box-shadow: hsla(0, 0%, 85%, 1) 1px 1px 3px 0px; */
    color: hsl(0, 0%, 0%);
    font-weight: 200;
    border-left: 1px solid hsl(208, 50%, 58%);

    display: flex;   
    flex-direction: column;

    border-bottom: 1px solid hsla(0, 0%, 94%, 1);
    border-right: 1px solid hsla(0, 0%, 94%, 1);
}

.msgButton>div{
    display: inherit;
    width: -webkit-fill-available;
}

.msgButton:hover{
    /* background-color: hsla(0, 0%, 98%, 1); */
    border-left: 3px solid hsl(208, 50%, 58%);
}

.msgButtonName{
    padding: 8px 10px;
    align-self: center;
    width: -webkit-fill-available;
}

.iconContainer{
    display: flex;
    align-items: center;
}

.msgButtonIconContainer{
    background-color: hsla(0, 0%, 97%, 1);
    border-right: 1px solid hsla(0, 0%, 90%, 1);
}

.msgButtonIcon{
    height: 1.5em;
    padding: 8px;
    opacity: 0.7;
}

.IconPlaceholder{
    opacity: 0;
}

.msgButtonMore{
    height: 1.5em;
    padding: 8px;
}

.more{
    float: right;
}

.flexItem{
    flex-grow: 1;
}

.nestedFlexContainer{
    display: flex;
}

.ServiceButtonDetailsContainer {
        height: 112px;
        overflow: hidden;
        transition: height .2s ease-in-out;
        /* height: 0; */
        background-color: hsl(0, 0%, 100%);
        color: #828282;
        /* font-size: .8em; */
        /* flex-direction: column; */
}

.ServiceButtonDetails{
    
    padding: 8px;
    width: -webkit-fill-available;
}

.ServiceButtonDescription{
    border-top: 1px solid hsla(0, 0%, 96%, 1);
    width: -webkit-fill-available;
    padding: 10px 8px 2px 11px;
    color: hsl(0, 0%, 33%);
}

.ServiceButtonSubgroup{
    font-size: .8em;
}

.resize{
    position: fixed;
    right: 0;
    height: 3.5em;
    bottom: 0;
}

@media all and (orientation:landscape) {
    .resize{
        display: none;
    }
}

.OpenButton{
    padding: 8px 17px;
    background-color: hsl(155, 100%, 36%);
    color: white;
    font-weight: 400;
    margin: 2px;
    border: 1px solid hsl(155, 100%, 36%);
}

.SaveButton{
    padding: 8px 17px;
    background-color: hsl(155, 100%, 36%);
    color: white;
    font-weight: 400;
    margin: 2px;

    background-color: white;
    color: #009657;
    border: 1px solid #009657;
}

.Button{
    float: right;
}




@media all and (orientation:landscape) {
    .ServiceListName, .ServiceListDescription, .msgButton{
        margin-left: 43vw;
    }

    .ServiceListName{
        margin-top: 4vh;
    }
}