.Header{
    height: calc(10px + 8vmin);
    background-color: #ff8300;
    position: fixed;
    z-index: 2;
    width: 100vw;
    top: 26px;
    box-shadow: #0000004f 1px 3px 4px 0px;
}

.Logo{
    float: left;
    width: 50vw;
    text-align: left;   
}

.Logo>img{
    height: 7vmin;
    padding: calc(2px + 1vmin);
}

.Flavour{
    float: right;
    width: 50vw;
    background-color: #484848;
    height: calc(10px + 8vmin);
    text-align: center;
    overflow: hidden;
}

.Flavour>img{
    height: 5vmin;
    padding: calc(7px + 1vmin);
}

@media all and (orientation:landscape) {
    .Header{
        width: 40vw;
    }

    .Flavour{
        float: right;
        width: 28vw;
        text-align: left;
    }
}