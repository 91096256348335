.ServiceDetails{
    float: left;
    text-align: left;
    color: #5c5f62;
    flex-grow: 100;
    width: 84vmin;
}

.ServiceName{
    font-weight: bold;
    margin: 9vmin 0px 0px 10px;
}

.ServiceDescription{
    font-size: calc(10px + 1vmin);
    line-height: calc(10px + 2vmin);
    padding-right: 6vw;
    margin: 1vmin 0px 0px 10px;
    color: #5c5f62d6;
}