.Cover{
    background-color: #e5eaf0;
    overflow: hidden;
    box-shadow: hsla(0, 0%, 0%, 0.16) 1px 11px 20px 0px;
    position: fixed;
    z-index: 1;
    transition: all .4s ease-in-out;
    max-height: 100vh;
}

@media all and (orientation:landscape) {
    .Cover{
        float: left;
        width: 40vw;
        height: 100vh;
    }
}